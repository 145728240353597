// 1. Your custom variables and variable overwrites.
@import "base/_variables.scss";

// 2. Import default variables and available mixins.
@import "../uikit/scss/variables-theme.scss";
@import "../uikit/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.
@mixin hook-card() { color: $blue; }
@mixin hook-base-link() { @extend .trans; }
@mixin hook-base-blockquote() { color: $lblue; }

// 4. Import UIkit.
@import "../uikit/scss/uikit-theme.scss";

// uk-hidden@s      640px and larger.
// uk-hidden@m      960px and larger.
// uk-hidden@l      1200px and larger.
// uk-hidden@xl     1600px and larger.

// 5. Import Fonts.
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,400i,600|Karla:400,400i,700,700i&display=swap');

// 5. Import Partials from Base
@import "base/_variables.scss";
@import "base/_mixins.scss";
@import "base/_core.scss";
@import "base/_header.scss";
@import "base/_footer.scss";
@import "base/_helpers.scss";

// 6. Import Partials from Pages
@import "pages/_home.scss";
@import "pages/_main.scss";
@import "pages/_landing.scss";
@import "pages/_event.scss";
@import "pages/_basic.scss";
@import "pages/_search.scss";
@import "pages/_post.scss";
@import "pages/_posts.scss";

// 7. Import Partials from Modules
@import "modules/_countdown.scss";
@import "modules/_slider.scss";
@import "modules/_pagination.scss";
@import "modules/_upcoming.scss";
@import "modules/_events-calendar.scss";
@import "modules/_map.scss";