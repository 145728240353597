#pager {
    ul {
        @extend .uk-width-expand, .uk-flex-center;
        text-align: center;
        padding: unset;
        li {
            @extend .uk-button, .uk-button-default, .blue-button;
            align-items: center;
            justify-content: center; 
            display: none;
            &.MarkupPagerNavPrevious {
                float: left;
            }
            &.MarkupPagerNavNext {
                float: right;
            }
            &.MarkupPagerNavNext,
            &.MarkupPagerNavPrevious {
                display: inline-flex;
                @include respond-to("max-width: " + $SMALL) {
                    width: 100%;
                    margin-bottom: 20px;               
                }
            }
            &.MarkupPagerNavPrevious {
                svg {
                    transform: rotate(180deg);
                }
            }
            a {
                display: flex;
                align-items: center;
                span {
                    display: block;
                    font-size: 1.25em;
                }
            }
        }
    }
}