#slider {
    ul {
        @extend .uk-padding-remove;
    }
    ul.uk-slider-items {
        height: 700px;
        li {
            @extend .uk-transition-toggle, .uk-flex, .uk-flex-center, .uk-flex-middle, .uk-text-center;
            &:hover {}
            a {
                @extend .bottom-shadow;
                .uk-panel {
                    &.text {
                        @extend .uk-width-3-4, .uk-flex , .uk-flex-column, .uk-flex-bottom, .uk-text-center;
                        pointer-events: none;
                        * {
                            @extend .uk-margin-auto;
                        }
                        .calendar {
                            display: flex;
                        }                                           
                    }                        
                    h4 {
                        @extend .uk-margin-remove, .uk-text-break;
                        @include text-shadow(2px, 2px, 5px, rgba(77,77,79,0.5));
                        color: $white;
                        line-height: 1;
                    }
                    h5 {
                        padding-top: 10px;
                        display: inline-flex;
                        border-top: $border solid $orange;
                    }
                    video {}
                }
            }
        }
    }
    .uk-position-center-left,
    .uk-position-center-right {            
        @include respond-to("max-width: " + $SMALL) {
            display: none;
        }
    }
    .uk-position-center-left {
        margin-left: -25px;   
    }
    .uk-position-center-right {
        margin-right: -25px;   
    }                 
    @include respond-to("min-width: " + $MEDIUM) {
        .uk-position-center-left {
            margin-left: -50px;   
        }
        .uk-position-center-right {
            margin-right: -50px;   
        }                  
    }
    
} 