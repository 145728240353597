[data-template=basic] {
    section {
        &.no-hero {
            .summary {
                * {
                    color: $black;
                }
            }
        }
        #hero {
            &:after {
                @extend .uk-position-cover;
                content: '';
                z-index: 1;
                background-color: $black;
                opacity: 0.2;
                display: block;
            }
            .uk-text-center {
                position: relative;
                z-index: 2;
            }      
        }
        .body {
            color: $black;
            h2, .uk-h2 {
                //@extend .uk-heading-medium;
                color: $dblue;
            }
            h3, .uk-h3 {
                //@extend .uk-heading-small;            
                color: $blue;            
            }
            h4, .uk-h4 {
                color: $orange;            
            }
            h5, .uk-h5 {
                color: $lblue;            
            }
            h6, .uk-h6 {
                color: $green;            
            }             
        }        
        main {
            &.body {}       
        }
        #content-grid {
            .wrap {                  
                .uk-first-column {
                    //padding: unset;
                }                
                &:nth-of-type(odd) {
                    .img {
                        @extend .uk-text-right;                     
                    }
                }
                &:nth-of-type(even) {
                    @extend .uk-flex-row-reverse;
                }
                .body {}
                .img {
                    @media (max-width: 640px) {                            
                        text-align: center;
                    }                     
                }                
            }
        }     
    }
    #sponsors {
        @extend .bottom-unset;
        background-color: $white;
        h3 {
            @extend .uk-margin-large;
        }
    }
    #lightbox {
        background: $green;
        div {
            margin-bottom: unset;
        }
    }      
}