* {}
html {
    background-color: $lgrey;
}
body {}
i {
	&:before,
	&:after {
		vertical-align: middle;
	    line-height: 1;					
	}
}
// Remove Glow in Chrome
:focus {
	outline-color: transparent;
	outline-style: none;
}
em {
    color: $black;
    font-weight: 600;
}
p, ul, ol, dl, pre, address, fieldset, figure {}
// Headings
.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6 {
    font-family: $barlow;
    font-weight: 600;
    line-height: 1;             
}
h1, .uk-h1 {}
h2, .uk-h2 {}
h3, .uk-h3 {}
h4, .uk-h4 {
    font-size: 1.5rem;
    font-weight: 400;
}
h5, .uk-h5 {
    font-size: 1.25rem;
    font-weight: 400;        
}
h6, .uk-h6 {}
ul {
    &.nav-tree {
        color: $black;
        a {
            color: inherit;
        }        
    }
}
hr {
    width: 100%;
    border-top-color: $black;
    &.wave {
        background: url("/site/assets/images/wave.svg") no-repeat;
        background-position: center center;
        height: 20px;
        width: 100%;
        border: unset;     
    }
    &.wave.left {
        background-position: left center;
        @include respond-to("max-width: " + $MEDIUM) {
            background-position: center center;
        }           
    }
    &.blue-hr {
        border-top-color: $blue;
    }
    &.dblue-hr {
        border-top-color: $dblue;
    }     
}
time {
    display: block;
}
.uk-hr {
    width: 50%;
    margin: auto;
}
video {
    width: 100% !important;
    object-fit: cover;
    &::-webkit-media-controls {
        display: none !important;
    }
}
article {
    background-color: $lblue;
    h2, h3 {
        @include text-shadow(2px, 2px, 5px, rgba(77,77,79,0.5));        
    }
}
.file {
    padding: 5px 10px;
    * {
        color: $dblue;            
    }
}
#hero {
    h1 {
        @include text-shadow(0px, 5px, 20px, rgba(77, 77, 79, 0.9)) ;               
    }             
}
.body {
    h2, .uk-h2 {
        color: $blue;
    } 
    h3, .uk-h3 {
        color: $blue;
    }    
    h4, .uk-h4 {
        color: $orange;
    } 
    h5, .uk-h5 {
        color: $lblue;
    }
    h6, .uk-h6 {
        color: $black;
    }

    hr {
        border-top-color: $white;
    }      
}
#lightbox {
    background-color: $green;
    margin-bottom: unset;
    .wrap {
        &:hover {
            img {
                @extend .bottom-shadow, .trans;              
            }
        }        
    }
}
.social {
    @extend .trans;
    position: absolute;
    //bottom: 20px;
    right: 0;
    h4 {
        @extend .uk-margin-remove;
        //transform-origin: top center 0;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);                
    }
    div {
        position: relative;
        &:before {
            content: '';
            width: 2px;
            height: 50px;
            background-color: $orange;
            position: absolute;
            top: -75px;
            left: calc(50% - 1px);                  
        }
        .uk-icon {
            @extend .uk-flex, .uk-flex-center, .uk-margin-small-bottom, .trans;                 
            &:hover {
                fill: $orange;
            }
        }                   
    }          
}
.grecaptcha-badge {
    display: none !important;
}

// SHARED STYLES 
[data-template=home],
[data-template=main] {
    .social {
        top: 50vh;
    }  
    #hero {
        // Video overlay .4
        ul.uk-slideshow-items {
            @media (max-width: 640px) {
                height: 70vh;
            }
            li {
                .uk-position-cover {
                    &:before {
                        z-index: 1;
                    }
                }
                .uk-text-left {
                    position: relative;
                    z-index: 2;
                    @media (max-width: 960px) {
                        width: 100%;
                        max-width: 100%;
                        .uk-container {
                            text-align: center;                        
                        }  
                    }
                }
            }
        }
        h1 {
            font-weight: 400;
        }
        .headline {
            @extend .uk-margin-large-bottom, .uk-heading-xlarge;
            * {
                //all: inherit;
                line-height: .9;
            }
        }         
        button {
            @extend .orange-button;
            [data-uk-icon] * {
                stroke: $white;
            }             
        }
    }
    .uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
       // stroke: $white;
    }
    #main {
        * {
            color: $white;            
        }
        .uk-button {
            background-color: transparent;            
            a {
                color: inherit;
            }
            &.uk-active {
                background-color: $orange;
                border-color: $orange;                                            
            }
            &:first-of-type {
            }
            &:last-of-type {
                @extend .uk-margin-left;
            }
        }       
    }
    #call-to {
        @extend .uk-margin-remove-top, .uk-text-center;
        overflow: hidden;
        min-height: 75vh;
        * {}
        video {}
        .text {
            min-height: 75vh;
        }        
    }
}

/* PROCESSWIRE IMAGE */

.align_left {
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
}

.align_right {
	float: right;
	margin-bottom: 20px;
	margin-left: 20px;
}
.align_center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

// UIKIT OVERRIDES
img[data-src][src*='data:image'] {
    background: $green;
}
.uk-accordion-title:before {
    background-image: url('/site/templates/uikit/images/icons/chevron-down.svg');
    width: 35px;
    height: 35px;
    background-size: 35px;      
}
.uk-open {
    .uk-accordion-title:before{
        background-image: url('/site/templates/uikit/images/icons/chevron-up.svg');
    }
}
.uk-offcanvas-overlay:before {
    background: rgba(77,77,79,0.8);
    
}
.uk-button, button {
    @extend .uk-border-rounded, .trans, .uk-text-uppercase;
    color: $white;
    @include respond-to("max-width: " + $SMALL) {
        padding: 10px;
        line-height: 1;
    }
    &:hover,
    &:focus {}     
    &.uk-button-default {}
    &.large-button {
        font-size: 2rem;
    }
    &.hide-border {
        border-color: transparent;
    }
    &.orange-button {
        background-color: $orange;
        border-color: $orange;
        &:hover,
        &:active {
            background-color: transparent;
            border-color: $orange;
            color: $orange;                      
            a {
                text-decoration: none;
                color: $orange;                      
            }
            .uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
                stroke: $orange;
            }        
        }        
    }
    &.blue-button {
        background-color: $lblue;
        border-color: $lblue;
        &:hover,
        &:active {
            background-color: transparent;
            border-color: $lblue;
            color: $lblue;                      
            a {
                text-decoration: none;
                color: $lblue;                      
            }
            .uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
                stroke: $lblue;
            }        
        }         
    }
    &[uk-toggle] {
        width: auto;
        @include respond-to("max-width: " + $SMALL) {
            width: 100%;
        }
    }       
    &:hover {
        a {
            text-decoration: none;            
        }        
    }
    a {
        @extend .uk-text-uppercase;
        color: inherit; 
        display: block;       
    }
}
