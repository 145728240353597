#map {
    background-color: $green;
    > div {
        @extend .uk-margin-remove-bottom;
    }
    h2 {
    }
    [data-uk-accordion] {
        @extend .uk-margin-remove;
        li {
            @extend .uk-padding-small, .uk-margin-remove;
            &:first-of-type {}
            hr {
                border-top-color: $white;
            }
        }            
        .uk-accordion-title {
            @extend .uk-text-uppercase, .uk-h2; 
            color: $white;
        }
        .uk-accordion-content {
            @extend .uk-padding-remove, .uk-margin-remove;
            li {
                padding: 0 0 15px;
                @include respond-to("max-width: " + $SMALL) {
                    padding-bottom: 5px;
                    img {
                        width: 25px;                            
                    }
                    span {
                        font-size: 10px;                            
                    }
                } 
                span {
                    line-height: 1;
                }                                       
            }
        }            
    }
    .uk-sticky-fixed {
        @extend .bottom-shadow;
        background-color: $green;            
    }
    svg {
        width: 100%;
        [class^="cls"] {
            path {
            }
        }
        #facilities, #livestock, #entertainment, #general-info {
            //@extend .trans, .uk-hidden;
            //display: none;
        }
    }
    
}