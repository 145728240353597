[data-structure=interior] {
    #main-nav {
        &.uk-sticky-below {
            vertical-align: top;        
        }
    }
}
#main-nav {
    width: 100%;
    z-index: 1000;
    &.uk-sticky-below {      
        > div {
            @extend .uk-box-shadow-medium;
        }
    }
    .uk-container {
        @include respond-to("max-width: " + $LARGE) {
            padding: unset;
        }
    }
    .uk-navbar {
        padding: 15px 0;
        @include respond-to("max-width: " + $MEDIUM) {                           
            padding: 5px 0;
        }
    }
    #burger {
        .uk-icon-button {
            width: 55px;
            height: 55px;
            border: 4px solid $orange;
            background-color: unset;
        }
        svg {
            stroke: $white;
            fill: $white;
            &:hover {
                rect {
                    &:nth-of-type(2) {
                        width: 16px;
                    }
                }                 
            }
            rect {
                @extend .trans;                                    
                &:nth-of-type(2) {
                    width: 6px;
                }
            }        
        }        
    }
    .uk-logo {
        color: $white;
        position: relative;
        z-index: 1000;
        // margin-bottom: -25px;
        .main-logo {
            * {
                fill: $white;
                 transition: fill 1s ease;                
            }
        }
    }
    .main {
        @extend .uk-text-bold, .uk-text-uppercase;
        * {
            color: $white;
            &:hover {
                color: $white !important;
            }                        
        }
        &.uk-active {}
        &:last-of-type {
            a {
                @extend .uk-button, .uk-button-default;
                color: $white;
                background-color: transparent;
            }
        }
        span[data-uk-icon] * {
            stroke: $orange;
        }
    }
    &.uk-sticky-below {
        @extend .uk-box-shadow-bottom;
        * {
            @extend .trans;
            color: $blue;
            &:hover {
                color: $blue !important;
            }            
        }
        > div {
            background-color: $white;            
        }
        .main-logo {
            * {
                fill: $black;                
            }
            @extend .add-color;                                                                 
        }        
        #burger {
            .uk-icon-button {
                border-color: transparent;
            }            
            * {
                stroke: $blue;       
            }        
        }        
        .main {
            &:last-of-type {
                a {
                    color: $blue;                    
                    border-color: $blue; 
                    //padding: 0 30px;                   
                }
            }
        }
    }    
}
#offcanvas-slide {
    .uk-offcanvas-bar {
        background-color: $white;
        color: $blue;            
        .main-logo {
            * {
                fill: $black;                
            }
            @extend .add-color;                                                                 
        }
        .social-off {
            * {
                color: $blue;
            }            
            hr {
                border-top-color: $orange;
            }
            [data-uk-icon] {
                &:not(:last-of-type) {
                    @extend .uk-margin-medium-right;                
                }
            }
        }
        form {
            &#search {
            @include respond-to("max-width: " + $MEDIUM) {
                * {
                    width: 50%;
                }
            }
                input {
                    border: 1px solid $orange;
                    border-radius: $border;
                    margin-right: 10px;
                    color: $black;
                }
            }
        }        
        .uk-nav {
            .uk-nav-divider {
                border-top-color: $lgrey;
            }
            .navitem {
                @extend .uk-h5, .uk-margin-remove;
                a {
                    @extend .uk-flex, .uk-flex-middle;
                    color: $lblue;
                    .icon {
                        min-width: 45px;                        
                    }
                    [data-uk-icon] {
                        @include respond-to("max-width: " + $MEDIUM) {                           
                        //    margin-right: 20px !important;
                        }
                        * {
                            stroke: $orange;                            
                        }
                    }                  
                }                
            }
        }
        .uk-button {
            @extend .uk-button-default, .orange-button, .uk-flex, .uk-flex-row, .uk-flex-center;
            font-size: 12px;
            @include respond-to("max-width: " + $MEDIUM) {                           
                padding: unset;
            }            
        }
    }  
}