$barlow:                        'Barlow Condensed', sans-serif;
$karla:                         'Karla', sans-serif;

$black:                         #4D4D4F;
$black_h:                       hsla(240, 1%, 31%, 1);
$black_a:                       rgba(77,77,79,1);
$white:                         #FFFFFF; // rgba(255,255,255,1);
$lgrey:                         #EDEFF2; // rgba(237,239,242,1);

$dblue:                         #005C9E; // rgba(0,92,158,1);
$blue:                          #007CC3; // rgba(0,124,195,1);
$lblue:                         #018DDE; // rgba(1,141,222,1);
$orange:                        #FFA033; // rgba(255,160,51,1);
$green:                         #6FAD77; // rgba(111,173,119,1);

// SVG Flag colors
$violet:                        #9D3D97;
$red:                           #EF3E33;
$purple:                        #653B71;
$teal:                          #008185;

// Responsive

$XLARGE:                        1600px;
$LARGE:                         1200px;
$MEDIUM:                        960px;
$SMALL:                         640px;
$XSMALL:                        450px;

// Helpers
$border:                        8px;

// UIKit Overrides
$global-font-family:            $karla;
$global-link-color:             $orange;
$global-danger-background:      $green;
$global-background:             $white;
$global-border:                 $white;
$inverse-base-hr-border:        $white;
$base-hr-border:                $white;
$base-hr-border-width:          2px !default;
$global-muted-background:       $blue;
$global-primary-background:     $blue;
$global-secondary-background:   $orange;
$base-body-color:               $white;
$base-heading-color:            $white;
$base-heading-text-transform:   uppercase;
$border-rounded-border-radius:  6px !default;
