.dblue {
    color: $dblue;
}
.blue {
    color: $blue;
}
.lblue {
    color: $lblue;
}
.orange {
    color: $orange;
}
.green {
    color: $green;
}
.black {
    color: $black;
}
.trans {
	transition: all .33s;
}
.no-decor {
    text-decoration: none !important;
}
.list-none {
    list-style: none;
}
.hr-buffer {
    border-color: $orange;
    width: 60%;
    margin: auto;
    border-width: 6px;                
}
.rad {
    border-radius: $border-rounded-border-radius;    
} 
.column {
    @extend .uk-flex, .uk-flex-column, .uk-flex-center;
}
.row {
    @extend .uk-flex, .uk-flex-row, .uk-flex-center;
}
.uk-text-tiny {
    font-size: 10px;
}
.overlay {
    &:before {
        @extend .uk-position-cover;
        content: '';
        z-index: 1;
        background-color: $black;
        opacity: 0.3;
    }    
}
.bottom-shadow {
    @include box-shadow(0, 30px, 25px, -30px, $black);                    
}
.bottom-unset {
    padding-bottom: unset !important;
    margin-bottom: unset !important;
}
.pattern {
    &:before,
    &:after {
        @extend .rad, .trans;
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
    }
    &:before {
        right: -20px;
        bottom: -20px;
        z-index: -1;
        pointer-events: none;                
        // background: url('/site/assets/images/diagonal-pattern.png') repeat top left;
        background-image: linear-gradient(45deg, rgba(0,0,0,.1) 25%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 50%, rgba(0,0,0,.1) 50%, rgba(0,0,0,.1) 75%, rgba(255,255,255,0) 75%, rgba(255,255,255,0) 100%);
        background-size: 20px 20px;        
        @include respond-to("max-width: " + $SMALL) {
            display: none;
        } 
    }
    &:after {
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;        
        z-index: 1;
        background-color: rgba(0,0,0,0.2);
    }
    &:hover {
        @extend .bottom-shadow;
        &:before {
            right: 0;
            bottom: 0;                
        }
    }    
}
.add-color {
    .sun {
        path {
            fill: $orange;
        }
    }
    .flag-one {
        path {
            fill: $blue;                        
        }                    
    }
    .flag-two {
        path {
            fill: $violet;                        
        }                    
    }
    .flag-three {
        path {
            fill: $red;                        
        }                    
    } 
    .flag-four {
        path {
            fill: $purple;                        
        }                    
    } 
    .flag-five {
        path {
            fill: $teal;                        
        }                    
    }
}