footer {
    z-index: 100;
    section {
        .wrap {
            //@extend .uk-flex-row;            
            margin-top: -30px;
            @media (max-width: 640px) {                            
                flex-direction: column;
                text-align: center;                
            }
            > div {
                @media (max-width: 640px) {                                            
                    margin-bottom: 40px !important;
                    justify-content: center;                                 
                }
            }
        }
    }
    .uk-logo {
        img {
            max-width: 200px;
        }
    }
    .uk-button {}
    .uk-flex-column {
        .link {
            @extend .uk-flex, .uk-flex-center, .uk-flex-middle, .uk-text-uppercase, .uk-h4, .uk-margin-remove, .no-decor, .uk-padding-small;
            font-weight: 400;
        }
    }
    #awards {
        .award {
            @extend .column, .uk-text-center;
            // padding: unset;
            span {
                line-height: 1;
                display: block;
                color: $white;
                &:first-of-type {
                    font-weight: 800;
                }
            }
        }
        svg {
            margin: 20px 0;
            .cls-1 {
                stroke: rgba($black, 0.6);                
            }
            .cls-2 {
                fill: rgba($black, 0.4);
            }
            &.gold {
                .cls-1 {
                    fill: #D4AF37;
                }
            }
            &.silver {
                .cls-1 {
                    fill: #C0C0C0;
                }
            }
            &.bronze {
                .cls-1 {
                    fill: #b08d57;
                }
            }
        }
    }
    #sign-up {
        border-radius: 6px 0 0 6px;
        > div {
            @media (max-width: 640px) {                            
                margin: auto;
            }            
        }
        .uk-input {
            @extend .uk-border-rounded;            
            background: transparent;
            border-color: $white;
        }
        h4 {
            @extend .uk-margin-remove;
            span {
                @include outlined($white);
                color: $orange;
            }
        }
        form {
            [data-qe-id=form-description] {
                text-align: center;
                font-size: 1.5rem;
                color: $white;
                text-transform: uppercase;
            }
            [data-qe-id=form-label-email] {
                color: $white;
            }
        }
    }
    .uk-section-secondary {
        * {
            color: $white;            
        }
        .uk-button {}
    }
    .uk-navbar-item {
        min-height: unset;
        padding: 2.5px 10px;        
        .uk-icon {
            @extend .uk-margin-small-right;
            &:last-of-type {
                @extend .uk-margin-remove;
            }
        }
    }
    .wrap {
        @media (max-width: 640px) {                                    
            flex-direction: column;
        }
        #social-foot {
            [data-uk-icon] {
                &:not(:last-of-type) {
                    @extend .uk-margin-medium-right;                
                }
            }
        }        
    }
}