[data-template=posts] {
    .social * {
        color: $blue;
    }
    section .body {
        color: $black;
    }
    > section {
        // margin-top: 70px;
        @extend .uk-margin-large, .uk-position-relative;
        @include respond-to("max-width: " + $LARGE) {
            margin-top: calc(5vw + 70px) !important;
            padding: unset;
        }
        article {
            @extend .trans, .uk-padding;
            background-color: darken($lgrey, 15%);
            &:hover {
                @extend .bottom-shadow;
            }
            .body {
                span {
                    @extend .uk-margin-small-left;
                    color: $orange;
                }
            }
        }
    }
}