@mixin outlined($color) {
    text-shadow: 2px 2px 0 $color, 2px -2px 0 $color, -2px -2px 0 $color, -2px 2px 0 $color;   
}
@mixin box-shadow($x, $y, $blur, $spread, $color) {
	-webkit-box-shadow: $x $y $blur $spread $color;
	-moz-box-shadow: $x $y $blur $spread $color;
	box-shadow: $x $y $blur $spread $color;	
}
@mixin inset-box-shadow($x, $y, $blur, $color) {
	-webkit-box-shadow: inset $x $y $blur $color;
	-moz-box-shadow: inset $x $y $blur $color;
	box-shadow: inset $x $y $blur $color;	
}
@mixin text-shadow($x, $y, $blur, $color) {
    -webkit-text-shadow: $x $y $blur $color;
    -moz-text-shadow: $x $y $blur $color;
    -ms-text-shadow: $x $y $blur $color; /* IE 10+ */
    -o-text-shadow: $x $y $blur $color;
    text-shadow: $x $y $blur $color;
}
@mixin respond-to($width) {
    @media screen and ($width) {
        @content;
    }
}