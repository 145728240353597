[data-template=landing] {
    section {
        // margin-top: 70px;
        @extend .uk-margin-large;
        @include respond-to("max-width: " + $LARGE) {
            margin-top: calc(5vw + 70px) !important;
            padding: unset;
        }
    }
    .page-title {
        line-height: 1;
        @media (max-width: 640px) {                                    
            margin: unset;
        }
        * {
            all: inherit;
        }
    }    
    #main-nav {
        &.uk-sticky-below > div {
            background-color: $white; 
        }
        > div {
            @include respond-to("max-width: " + $LARGE) {
                padding: unset;
            }
        }
    }
    #hero {
        &:after {
            @extend .uk-position-cover;
            content: '';
            z-index: 1;
            background-color: $black;
            opacity: 0.2;
            display: block;
        }
        * {
            color: $white;
        }  
        .uk-text-center {
            position: relative;
            z-index: 2;
        }            
    }
    .uk-panel {
        @extend .uk-flex , .uk-flex-column, .uk-flex-bottom, .uk-text-center;        
        video {
            max-width: inherit;
            overflow: hidden;
        }
        .text {
            width: 100%;
            pointer-events: none;
            h2 {
                
            }    
        }
    }
    section {
        .summary, .body {
            color: $black;                            
            * {
                // color: inherit;                
            }
        }
        hr {
        }
        #all-events {
            article {
                h3 {
                    //line-height: 1;
                }
            }
        }
    }
    .social {
        // bottom: unset;
        * {
            color: $blue;
        }            
    }    
}
[data-parent=entertainment] {
    .page-title {
        line-height: 1;
        * {
            all: inherit;
        }
    }
    .wrap {
        &.uk-scrollspy-inview {
            article {
                &:before,
                .caldendar {
                    
                }
            }
        }
    }
}
[data-page=facilities] {
    article {
        .text {
            //@extend .uk-width-expand;
            width: 100%;
            z-index: 2;
        }
    }
}