[data-template=event] {
    #main {        
        hr.wave {
            @extend .uk-margin-small-bottom;
        }
        .pattern {
            width: auto;
            display: inline-flex;
            position: relative; 
            @include respond-to("max-width: " + $SMALL) {
                display: block;
            }
        }
        #bio {
            @include respond-to("max-width: " + $MEDIUM) {
                //order: 2;
                * {
                    text-align: center;
                }
            }
            .calendar {
                @include respond-to("max-width: " + $MEDIUM) {
                    justify-content: center;
                }
                time {
                    margin: 0;
                    line-height: 1;
                    &.month {
                        color: $lblue;                
                    }
                    &.day {
                        @include outlined(rgba(77,77,79,0.3));                
                        color: $lgrey;                
                    }
                }  
            }            
        }
        .text {
            @include respond-to("max-width: " + $MEDIUM) {
                h1 {
                    text-align: center;
                }
            } 
            time {
                display: inline-flex;
            }           
            #facility {
                a {
                    color: inherit;
                }
            }
            .summary, .body {
                color: $black;
            }            
        }
    }
    #upcoming {
        article {
            width: 100% !important;
            background-color: $lblue;
        }
    }
}