#events-calendar {
    background-color: $lgrey;
    ul {
        padding: unset;
        &[uk-switcher] {
            @media (max-width: 640px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
    li.uk-h2 {
        @extend .uk-heading-medium, .trans;
        margin: unset;
        // display: inline-flex;
        list-style-type: none;
        &.uk-active {
            a {
                //@include text-shadow(0, 2px, 10px, rgba(0,0,0,0.2));
                color: $orange;
            }
        }
        &.events {
            padding-right: 20px;
            border-right: $border solid $orange;
            @media (max-width: 640px) {
                padding: unset;
                border: unset;                
            }
        }
        &.calendar {
            padding-left: 20px;
            @media (max-width: 640px) {
                padding: unset;
            }                
        }
        a {
            @extend .dblue, .no-decor;                
        }
    }
    .item {
        @extend .no-decor, .trans;
        padding: 20px 0;
        &:hover {
            @extend .bottom-shadow;
        }
        a {
            @extend .no-decor;                
        }            
        .calendar {
            time {
                margin: unset;
            }
            .month {
                font-weight: 600;                    
            } 
            .day {} 
        }
        .text {
            padding-left: 20px;
            border-left: 2px solid $orange;
            h3 {}
            .body {
                span {
                    @extend .uk-margin-small-left;
                    color: $orange;
                    svg {
                        stroke: $orange !important;
                    }
                }
            }
        }
    }
}