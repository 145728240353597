#countdown {
    background-color: $white;        
    .uk-countdown {
        * {
            @extend .uk-text-uppercase, .uk-text-center;
            color: $dblue;
        }
        [uk-grid] {
            @include respond-to("max-width: " + $SMALL) {
                flex-direction: column;
                align-items: center;            
            }
        }
        .wrap {
            @extend .uk-flex-middle, .uk-flex-column, .uk-padding-remove, .uk-margin-remove; 
            @include respond-to("min-width: " + $SMALL) {
                border-right: 4px solid $orange; 
            } 
            * {
                @extend .uk-padding-remove, .uk-margin-remove;
                color: $dblue;
            }                                                
            &:last-of-type {
                border: unset;
            }                      
            .uk-countdown-number {
                @extend .uk-heading-medium, .trans;
                span {}
            }
            .uk-countdown-separator {
                color: $lblue;                    
                font-weight: 600;
                line-height: 1;                   
                /* Phone landscape and bigger */
                @include respond-to("min-width: " + $SMALL) {
                    font-size: 1.35rem;
                }              
            }    
        }    
    }        
}