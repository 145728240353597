$colors: $lblue, $green, $blue, $orange, $dblue;

#upcoming,
#pavilion {
    @include respond-to("max-width: " + $SMALL) {
        //padding: unset;
    }
    a {
        @for $i from 1 through length($colors) {
        	&:nth-child(#{length($colors)}n+#{$i}) article {
        		background-color: nth($colors, $i);
            }
        }  
        article {
            @extend .uk-height-large, .uk-height-max-large;
            background-position: top center;
            h3 {
                @include respond-to("min-width: " + $LARGE) {
                    font-size: 2rem;
                }
                @include respond-to("max-width: " + $LARGE) {
                    font-size: 2.5rem;
                }
                @include respond-to("max-width: " + $MEDIUM) {
                    font-size: calc(1.5rem + 1vw);
                }
            }
            &:hover {
                .calendar {
                    top: 0;
                    left: 0;
                }            
            }
            .calendar {
                @extend .trans;
                pointer-events: none;
                border-radius: 0 0 $border 0;                       
                padding: 10px 20px;
                top: -10px;
                left: -10px;
                z-index: 10;
                background-color: $lgrey;
                time {
                    color: $black;                
                    margin: 0;
                    line-height: 1;
                    &.month {}
                    &.day {}
                }            
            }
            .text {
                z-index: 2;
            }
        }
    }
}