[data-template=main] {
    #hero {
        background-color: $white;
        background-size: cover;
        @include respond-to("max-width: " + $SMALL) {                                               
            //min-height: 75vh !important;
            //align-items: flex-end;
        } 
        .headline {
        }       
    }
    #main {
        p {
            // color: $black;
        }
    }
    #lightbox {
        background-color: $green;
    }
}