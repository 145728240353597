[data-template=search] {
    form.search {
        * {
            margin: 10px;
        }
    }
    ul.nav {
        color: $orange;
        .summary {
            @extend .uk-text-small;
            color: $black;
        }        
    }
}